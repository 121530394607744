import React from "react";
import ReactWordCloud from 'react-wordcloud';
import PropTypes from "prop-types"
import { css } from "@emotion/core"
import kebabCase from 'lodash/kebabCase'

// Components
import { Helmet } from "react-helmet"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout";

// this page lists all the tags, and how many posts there are with each tag
// it then links to another page for each tag, that page in turn is generated
// through gatsby-node.js and uses the template tags.js in the templates directory
const options = { fontSizes: [16, 72,], };
const TagsPage = ({
    data: {
        allMarkdownRemark: { group },
        site: {
            siteMetadata: { title },
        },
    },
}) => (

        < Layout >
            <div className="blog-main">
                <Helmet title={title} />
                <div className="blog-inner">
                    <h1 css={css`
            text-align: center;
            margin-top: 50px;
          `}>Ämnen</h1>
                    <ReactWordCloud options={options} words={group.map(item => { return { text: item.fieldValue, value: item.totalCount } })} />
                    {<ul css={css`text-align: center; list-style:none;`}>
                        {group.map(tag => (
                            <li key={tag.fieldValue}>
                                <Link to={`/tags/${kebabCase(tag.fieldValue)}/`}>
                                    {tag.fieldValue} ({tag.totalCount})
            </Link>
                            </li>
                        ))}
                    </ul>}
                </div>
            </div>
        </Layout >
    )

TagsPage.propTypes = {
    data: PropTypes.shape({
        allMarkdownRemark: PropTypes.shape({
            group: PropTypes.arrayOf(
                PropTypes.shape({
                    fieldValue: PropTypes.string.isRequired,
                    totalCount: PropTypes.number.isRequired,
                }).isRequired
            ),
        }),
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                title: PropTypes.string.isRequired,
            }),
        }),
    }),
}

export default TagsPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`